import { ApiGroup } from 'axios-actions'

const auth = (axios) => {
  return new ApiGroup(axios, {
    validateRestoreKey: '/api/auth/validatetempkey',
    login: 'POST /api/auth/client',
    facebook: 'POST /api/client/fblogin',
    google: 'POST /api/client/glogin',
    apple: 'POST /api/client/applelogin',
    restore: 'POST /api/auth/forgotpass',
    reset: {
      method: 'PUT',
      url: '/api/auth/forgotpass'
    },
    register: 'POST /api/client',
    check: '/api/auth/check',
    logout: 'DELETE /authenticated/site/auth',
    clientTickets: '/authenticated/site/client/order',
    getClientTicketByOrderId: '/authenticated/site/client/order/:id',
    client: '/authenticated/site/client',
    updateClient: 'PUT /authenticated/site/client/profile',
    updatePassword: 'PUT /authenticated/site/client/profile/update_password'
  })
}

export default auth
