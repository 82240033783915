export default async function ({ isHMR, store, redirect, app }) {
  if (isHMR) {
    return
  }

  const check = await app.$api.auth.use('data').check()
  if (check.uid) {
    return redirect(app.localePath({ name: 'profile' }))
  }
}
