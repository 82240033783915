
import { mapGetters } from 'vuex'
import Timer from '@/mixins/timer'
export default {
  name: 'Blank',
  mixins: [Timer],
  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    return {
      ...i18nSeo,
      title: this.$t('meta.title')
    }
  },
  computed: {
    ...mapGetters({
      cartOpen: 'cart/open',
      cartRoutes: 'router/cartRoutes'
    }),
    cartControlVisible() {
      return this.count > 0
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.$store.dispatch('cart/CART_LOAD')
      }
    },
    '$i18n.locale': {
      handler() {
        this.$store.dispatch('cart/CART_LOAD')
      }
    }
  },
  async mounted() {
    // try {
    //   await this.$recaptcha.init()
    // } catch (e) {
    //   console.log(e)
    // }
  }
}
