
export default {
  data: () => ({
    expand: false
  }),
  methods: {
    accept() {
      this.$refs.consent.accept()
    }
  }
}
