
import ModalMixin from '@/mixins/modal'
import Modal from '@/components/elements/Modal'
import { gtmDataLayer } from '~/utils/ga'
export default {
  mixins: [ModalMixin],
  props: {
    ticket: {
      type: null,
      default: null
    }
  },
  computed: {
    discount() {
      return this.ticket.discounts.find(
        (discount) => discount.id === this.ticket.discount_id
      )
    },
    price() {
      if (this.ticket.promo_code) {
        const promoCode = this.ticket.promo_code
        const type = parseInt(promoCode.type)

        if (type === 1) {
          return (
            this.ticket.price -
            (this.ticket.price / 100) * this.ticket.promo_code.value
          )
        }

        if (type === 2) {
          return this.ticket.price - this.ticket.promo_code.value
        }

        if (promoCode.type === 3) {
          return this.ticket.promo_code.value
        }
      }

      if (this.ticket.discount_id && this.discount) {
        return (
          this.ticket.price -
          (this.ticket.price / (100 / parseFloat(this.discount.percent)) +
            parseFloat(this.discount.fixed))
        )
      }

      return this.ticket.price
    }
  },
  methods: {
    remove(id) {
      this.$store
        .dispatch('cart/REMOVE_TICKET', {
          basket_id: id
        })
        .then(() => {
          gtmDataLayer({
            event: 'remove_from_cart',
            ecommerce: {
              currency: this.ticket.currency,
              value: Number(this.ticket.price),
              items: [
                {
                  item_id: String(this.ticket.price_zone_id),
                  item_name: this.ticket.event_name,
                  currency: this.ticket.currency,
                  price: Number(this.ticket.price),
                  quantity: 1
                }
              ]
            }
          })
          this.$root.$emit('ticket:remove', this.ticket)
        })
        .catch((error) => {
          this.$modal.show(
            Modal,
            {
              title: 'Oooops!',
              text: error.message
            },
            {
              height: 'auto',
              width: this.modalWidthCalc(),
              adaptive: true,
              scrollable: true,
              clickToClose: false
            }
          )
        })
    },
    setDiscount(id, e) {
      this.$store.dispatch('cart/SET_DISCOUNT', {
        basket_item_id: id,
        discount_id: Number(e.target.value)
      })
    }
  }
}
