import Auth from './providers'

function plugin(Vue, options) {
  Vue.auth = new Auth(Vue, options)

  Object.defineProperties(Vue.prototype, {
    $auth: {
      get() {
        return Vue.auth
      }
    }
  })
}

export default plugin
