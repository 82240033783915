export default {
  methods: {
    modalWidthCalc() {
      if (this.$mq === 'sm') {
        return '90%'
      } else if (this.$mq === 'md') {
        return '50%'
      } else {
        return '35%'
      }
    }
  }
}
