import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
  configure
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

setInteractionMode('passive')

// phone validator - allow numbers and plus before them
extend('phone', {
  params: ['isValid'],
  validate: (value, { isValid }) => {
    return isValid
  }
})

// password confirmation validator.
extend('password', {
  params: ['target'],
  validate: (value, { target }) => {
    return value === target
  }
})

// checks for capital character
extend('capital', {
  validate: (value) => {
    return value.match(/[A-Z]/g)
  }
})

// checks for capital character
extend('lowercase', {
  validate: (value) => {
    return value.match(/[a-z]/g)
  }
})

// checks for number
extend('number', {
  validate: (value) => {
    return value.match(/[0-9]/g)
  }
})

// checks for special character and not space
extend('special', {
  validate: (value) => {
    return value.match(/[^A-Za-z0-9\s]/g)
  }
})

// detects spaces in string
extend('spaces', {
  validate: (value) => {
    return value.match(/^\S*$/g)
  }
})

// loop over all rules
for (const rule in rules) {
  // add the rule
  extend(rule, {
    ...rules[rule] // eslint-disable-line import/namespace
  })
}

export default function ({ app }) {
  configure({
    defaultMessage: (field, values) => {
      // values._field_ = app.i18n.t(`${field}`)
      values._field_ = ''
      return app.i18n.t(`validation.${values._rule_}`, values)
    }
  })
}
