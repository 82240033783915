
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      searchVisible: 'search/visible',
      count: 'cart/count',
      cartRoutes: 'router/cartRoutes',
      mobileLayout: 'mobileLayout'
    }),
    cartControlVisible() {
      return this.count > 0 || this.cartRoutes.includes(this.getRouteBaseName())
    },
    showIfMobileSearchActive() {
      return !this.isMobile || (!this.searchVisible && this.isMobile)
    },
    isMobile() {
      return this.mobileLayout.includes(this.$mq)
    },
    locales() {
      const _locales = {}

      this.$config.available_languages.map((code) => {
        _locales[code] = this.$t('misc.langselector', code)
        return code
      })

      return _locales
    }
  },
  methods: {
    navigate(val) {
      this.$router.push(this.localePath({ name: val }))
    }
  }
}
