export const gtmScript = (id) => `
  <!-- Google Tag Manager -->
  (function(w,d,s,l,i){var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', '${id}');
  <!-- End Google Tag Manager -->
`
export const gtmInjectBodyScript = (id) => {
  // Load Tag Manager script.
  const gtmScript = document.createElement('script')
  gtmScript.async = true
  gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${id}`

  const firstScript = document.getElementsByTagName('script')[0]
  firstScript.parentNode.insertBefore(gtmScript, firstScript)
}

export const gtmNoscript = (id) => `
  <!-- Google Tag Manager (noscript) -->
  <iframe src="//www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
  <!-- End Google Tag Manager (noscript) -->
`
