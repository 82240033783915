import { render, staticRenderFns } from "./Mobile.vue?vue&type=template&id=332f846e&scoped=true&"
import script from "./Mobile.vue?vue&type=script&lang=js&"
export * from "./Mobile.vue?vue&type=script&lang=js&"
import style0 from "./Mobile.vue?vue&type=style&index=0&id=332f846e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "332f846e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsTimer: require('/home/ticketshoplv/public_html/ticketshop.lv/components/elements/Timer.vue').default})
