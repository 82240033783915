const initialState = () => {
  return {
    session: null,
    consent: false,
    available: null,
    widget: null,
    widgetOrgId: null
  }
}

export const state = () => {
  return initialState()
}

export const mutations = {
  SET_AVAILABLE(state, payload) {
    state.available = payload
  },
  SET_CONSENT(state, payload) {
    state.consent = payload
  },
  SET_WIDGET(state, payload) {
    state.widget = payload
  },
  SET_WIDGET_ORG_ID(state, payload) {
    state.widgetOrgId = payload
  },
  SET_SESSION(state, payload) {
    state.session = payload
  }
}

export const actions = {
  setAvailable(context, payload) {
    return context.commit('SET_AVAILABLE', payload)
  },
  setConsent(context, payload) {
    return context.commit('SET_CONSENT', payload)
  },
  setWidget(context, payload) {
    return context.commit('SET_WIDGET', payload)
  },
  setWidgetOrgId(context, payload) {
    return context.commit('SET_WIDGET_ORG_ID', payload)
  },
  setSession(context, payload) {
    return context.commit('SET_SESSION', payload)
  }
}

export const getters = {
  session: (state) => state.session,
  available: (state) => state.available,
  consent: (state) => state.consent,
  widget: (state) => state.widget,
  widgetOrgId: (state) => state.widgetOrgId,
  isWidget: (state, getters) => getters.widget || getters.widgetOrgId
}
