
import { mapGetters } from 'vuex'
import Timer from '@/mixins/timer'
export default {
  name: 'DefaultLayout',
  mixins: [Timer],
  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    return {
      ...i18nSeo,
      title: this.$t('meta.title'),
      bodyAttrs: {
        class: !this.cookieConsent && !this.$config.WIDGET ? 'ofh' : '',
        ...i18nSeo.bodyAttrs
      }
    }
  },
  computed: {
    ...mapGetters({
      cartOpen: 'cart/open',
      cartRoutes: 'router/cartRoutes',
      cartPages: 'router/cartPages',
      count: 'cart/count',
      mobileLayout: 'mobileLayout',
      cookieConsent: 'cookies/consent'
    }),
    ticketsRoute() {
      return ['events-eid-tickets'].includes(this.getRouteBaseName())
    },
    cartRoute() {
      return this.cartPages.includes(this.getRouteBaseName())
    },
    cartControlVisible() {
      return this.count > 0 && this.isMobile
    },
    cartVisible() {
      return this.count > 0 || this.cartRoutes.includes(this.getRouteBaseName())
    },
    isMobile() {
      return this.mobileLayout.includes(this.$mq)
    }
  },
  watch: {
    cartControlVisible: {
      imeediate: true,
      handler(val) {
        if (!val) {
          return this.$store.dispatch('cart/TOGGLE_CART', false)
        }
      }
    },
    cartVisible: {
      immediate: true,
      handler: function(val) { // eslint-disable-line
        // open if cart should be visible
        this.$nextTick(() => {
          if (!val) {
            return this.$store.dispatch('cart/TOGGLE_CART', false)
          }

          if (!this.mobileLayout.includes(this.$mq)) {
            this.$store.dispatch('cart/TOGGLE_CART', !!val)
          }
        })
      }
    },
    $route: {
      immediate: true,
      handler() {
        this.$store.dispatch('cart/CART_LOAD')
      }
    },
    '$i18n.locale': {
      handler() {
        this.$store.dispatch('cart/CART_LOAD')
      }
    }
  },
  async mounted() {
    // try {
    //   await this.$recaptcha.init()
    // } catch (e) {
    //   console.log(e)
    // }
  }
}
