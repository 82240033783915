export default class GIS {
  constructor(options) {
    this.options = options
    this.init()
  }

  init() {
    if (!window.google || !window.google.accounts) {
      this.loadScript(document, 'script', 'gis-js')
    } else {
      this.login()
    }
  }

  login() {
    const token = window.gis.requestAccessToken()
    if (token) {
      this.options.callback(token)
    }
  }

  load() {
    window.gis = window.google.accounts.oauth2.initTokenClient({
      client_id: this.options.client_id,
      scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
      ux_mode: 'popup',
      callback: (response) => {
        if (response?.access_token) {
          this.options.callback(response.access_token)
        }
      }
    })

    this.login()
  }

  loadScript(d, s, id) {
    const fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    const js = d.createElement(s)
    js.id = id
    js.onload = () => {
      this.load()
    }
    js.src = 'https://accounts.google.com/gsi/client'
    js.setAttribute('async', '')
    js.setAttribute('defer', '')
    fjs.parentNode.insertBefore(js, fjs)
  }
}
