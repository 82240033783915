import { CartError } from '@/utils/errors'
import { findPixelByVendor } from '@/utils/pixels/helpers'
import { tiktokEvent } from '~/utils/pixels/tiktok'

const initialState = () => {
  return {
    open: false,
    content: {},
    fees: {},
    insurance: {},
    tracking: [],
    time: 0,
    ready: false,
    insurancePrice: 0,
    currency: null
  }
}

export const state = () => {
  return initialState()
}

export const actions = {
  TOGGLE_CART(context, payload) {
    context.commit('HYDRATE', {
      open: payload
    })
  },
  UPDATE_TIME(context, payload) {
    context.commit('HYDRATE', {
      time: payload
    })
  },
  EXPIRE(context, payload) {
    return this.$axios.post(`/api/cart/expire`).then((response) => {
      context.commit('HYDRATE', {
        content: {},
        fees: {},
        currency: null
      })
    })
  },
  CART_LOAD(context, payload) {
    return this.$axios
      .get(`/api/cart`)
      .then((response) => {
        context.commit('UPDATE_CART', response.data)
      })
      .catch((e) => {
        console.log(e)
      })
  },
  SET_DISCOUNT(context, payload) {
    this.$axios[parseInt(payload.discount_id) === 0 ? 'delete' : 'put'](
      `/api/cart/${payload.basket_item_id}/discount`,
      payload
    ).then((response) => {
      context.commit('UPDATE_CART', response.data)
    })
  },
  SET_INSURANCE(context, payload) {
    return this.$axios[context.getters.insuranceActivated ? 'delete' : 'post'](
      `/api/cart/insurance`
    ).then((response) => {
      context.commit('UPDATE_CART', response.data)
    })
  },
  SET_PROMOCODE(context, payload) {
    return this.$axios
      .put(`/api/cart/promocode`, {
        promocode: payload
      })
      .then((response) => {
        context.commit('UPDATE_CART', response.data)
      })
  },
  async SUBMIT_ORDER({ dispatch, getters }, payload) {
    dispatch('SHOW_LOADING_OVERLAY', 'misc.processing_order', { root: true })

    const referrer = await this.$cookiz.get('ts__cookie__referer')
    const utm = await this.$cookiz.get('ts__cookie__query')
    const landing = await this.$cookiz.get('ts__cookie__landing')

    const client = {
      name: payload.newclient.name,
      surname: payload.newclient.surname,
      email: payload.newclient.email,
      phone: payload.newclient.phone,
      spam: payload.newclient.spam
    }

    const legal = {
      name: payload.legal_name,
      regnr: payload.legal_regnr,
      address: payload.legal_address,
      vat: payload.legal_vat
    }

    return this.$axios
      .post('/api/client/simplified', {
        newclient: client,
        payment_method_id: payload.payment_method_id,
        legal: payload.legalChecked ? legal : null,
        personalisations: payload.personalisations || null,
        referral: referrer || null,
        utm: utm || null,
        landing: landing || null
      })
      .then((response) => {
        if (typeof response !== 'object' || response === null) {
          throw new CartError(`Service error! ${JSON.stringify(response)}`, 500)
        }

        const { data } = response

        findPixelByVendor(getters.tracking || [], 'facebook').map((id) => {
          return this.$facebook.event(
            id,
            'InitiateCheckout',
            {},
            data.tracking_event_id
          )
        })

        findPixelByVendor(getters.tracking || [], 'TikTok').map((id) => {
          return tiktokEvent(id, 'InitiateCheckout', {}, data.tracking_event_id)
        })

        return data
      })
      .catch((e) => {
        dispatch('HIDE_LOADING_OVERLAY', null, { root: true })
      })
  },
  ADD_SEAT(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.put(`/api/cart/seat`, payload).then(
        (response) => {
          context.commit('UPDATE_CART', response.data)
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  ADD_TICKET(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.put(`/api/cart/standing`, payload).then(
        (response) => {
          context.commit('UPDATE_CART', response.data)
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  async CHECK_ORDER(context, payload) {
    try {
      const order = await this.$axios.$get(`/api/order/${payload}`)

      return {
        success: true,
        pending: false,
        error_id: null,
        order
      }
    } catch (e) {
      const response = e.response
      if (parseInt(response.status) === 425) {
        return {
          success: false,
          pending: true,
          order: null,
          error_id: null
        }
      }

      if (response.data?.data?.transaction) {
        return {
          success: false,
          pending: false,
          order: null,
          error_id: response.data.data.transaction?.result_code
        }
      }
      throw new CartError(`Order not found!`, 404)
    }
  },
  REMOVE_TICKET(context, payload) {
    if (context.getters.count) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(`/api/cart`, {
            data: payload
          })
          .then(
            (response) => {
              context.commit('UPDATE_CART', response.data)
              resolve(response.data)
            },
            (error) => {
              reject(error)
            }
          )
      })
    } else {
      return Promise.reject(new CartError(`No items in basket`, 404))
    }
  }
}

export const getters = {
  ready: (state) => state.ready,
  open: (state) => state.open,
  // returns tickets object
  content: (state) => state.content,
  fees: (state) => state.fees,
  currency: (state) => state.currency,
  tracking: (state) => state.tracking,
  // returns qty of tickets in cart
  count: (state) => {
    return Object.values(state.content).length
  },
  countById: (state, getters) => (id) => {
    return Object.keys(getters.content).filter(
      (ticket) =>
        parseInt(getters.content[ticket].price_zone_id) === parseInt(id)
    ).length
  },
  cartTime: (state, getters) => {
    // if cart is empty return 15 minutes
    return getters.count ? state.time : 900
  },
  insuranceActivated: (state, getters) => {
    return !!Object.values(getters.content).find(
      (item) => item.insurance_applied === 1
    )
  },
  insuranceAvailable: (state, getters) => {
    return !!Object.values(getters.content).find(
      (item) => item.insurance_available
    )
  },
  showPaymentInsurance: (state, getters) => {
    return !getters.insuranceActivated && getters.insuranceAvailable
  },
  insuranceTotalCost: (state, getters) => {
    return state.insurance.price || 0
  },
  total: (state, getters) => {
    const { discount, fees, insurance, total, venue } = getters.fees
    return total + insurance + fees + venue - discount
  }
}

export const mutations = {
  RESET_STATE(state, payload) {
    Object.assign(state, initialState())
  },
  HYDRATE(state, payload) {
    Object.assign(state, payload)
  },
  UPDATE_TIME(state, value) {
    state.time = value
  },
  UPDATE_CART(state, payload) {
    state.content = {}

    state.fees = { ...payload.fees }

    state.insurance = { ...payload.insurance }

    state.tracking = [...payload.tracking]

    state.time = payload.seconds_left

    state.currency = payload.currency

    const cartAddData = payload.items

    for (let i = 0, ic = cartAddData.length; i < ic; i++) {
      const ticket = cartAddData[i]

      const obj = {}
      obj[ticket.id] = ticket
      state.content = { ...state.content, ...obj }
    }

    state.ready = true
  }
}
