const initialState = () => {
  return {
    backLink: {
      name: 'index',
      params: {}
    },
    paymentPage: 'payment',
    descriptionPage: 'index',
    landingRoutes: ['index', 'tickets', 'payment', 'payment-hash', 'cart'],
    cartRoutes: ['events-eid-tickets'],
    cartPages: ['cart']
  }
}

export const state = () => {
  return initialState()
}

export const mutations = {
  SET_BACKLINK(state, payload) {
    state.backLink = payload
  }
}

export const actions = {
  setBacklink(context, payload) {
    return context.commit('SET_BACKLINK', payload)
  }
}

export const getters = {
  backLink: (state) => state.backLink,
  paymentPage: (state) => state.paymentPage,
  descriptionPage: (state) => state.descriptionPage,
  cartRoutes: (state) => state.cartRoutes,
  cartPages: (state) => state.cartPages,
  landingRoutes: (state) => state.landingRoutes
}
