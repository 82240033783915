import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'

import 'dayjs/locale/ru'
import 'dayjs/locale/lt'
import 'dayjs/locale/lv'
import 'dayjs/locale/et'
import 'dayjs/locale/ka'

dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(duration)

dayjs.tz.setDefault('Europe/Riga')

const timezonedDayjs = (...args) => {
  return dayjs(...args)
  // return dayjs(...args).tz()
}

const timezonedUnix = (value) => {
  return dayjs.unix(value)
  // return dayjs.unix(value).tz()
}

timezonedDayjs.unix = timezonedUnix
timezonedDayjs.duration = dayjs.duration

export default timezonedDayjs
