import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      timer: null
    }
  },
  computed: {
    ...mapGetters({
      count: 'cart/count'
    }),
    cartTime: {
      get() {
        return this.$store.getters['cart/cartTime']
      },
      set(value) {
        this.$store.dispatch('cart/UPDATE_TIME', value)
      }
    }
  },
  watch: {
    count: {
      immediate: true,
      handler: function(newVal, oldVal) { // eslint-disable-line
        if (process.client) {
          this.timer && this.timerStop()
          if (newVal === oldVal || newVal === 0) {
            return
          }

          this.timerStart()
        }
      }
    }
  },
  methods: {
    timerStart() {
      this.timer = setInterval(() => {
        /**
         * if time is not over - decrease time
         *
         * if time is over - clear cart and stop timer
         * at the same time on the backend we also clear cart for double control
         */
        this.cartTime > 0
          ? this.cartTime--
          : this.$store.dispatch('cart/EXPIRE').then(this.timerStop())
      }, 1000)
    },
    timerStop() {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  destroyed() {
    if (this.timer) {
      // If the timer is running, turn it off
      this.timerStop()
    }
  }
}
