
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      cartOpen: 'cart/open',
      count: 'cart/count'
    })
  },
  methods: {
    toggleCart() {
      this.$store.dispatch('cart/TOGGLE_CART', !this.cartOpen)
    }
  }
}
