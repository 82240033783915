const initialState = () => {
  return {
    visible: false,
    events: [],
    limit: 12,
    offset: -1,
    total: 0
  }
}

export const state = () => {
  return initialState()
}

export const mutations = {
  TOGGLE_SEARCH(state, payload) {
    state.visible = payload
  },
  HYDRATE(state, payload) {
    Object.assign(state, payload)
  }
}

export const actions = {
  show(context) {
    context.commit('TOGGLE_SEARCH', true)
  },
  hide(context) {
    context.commit('TOGGLE_SEARCH', false)
  },
  async search(context, payload) {
    let offset = payload.clear ? 0 : context.getters.offset
    const limit = context.getters.limit
    const result = await this.$axios.$get(`/api/event`, {
      params: {
        ...payload.params,
        offset,
        limit
      }
    })
    const data = result.data
    const { total } = result.meta
    const storeEvents = context.getters.events

    if (data.length) {
      offset += limit
    }

    const events = payload.clear ? data : [...storeEvents, ...data]

    context.commit('HYDRATE', {
      offset,
      events,
      total
    })
  }
}

export const getters = {
  visible: (state) => state.visible,
  events: (state) => state.events,
  offset: (state) => state.offset,
  limit: (state) => state.limit,
  total: (state) => state.total
}
