const initialState = () => ({
  fields: {}
})

export const state = () => {
  return initialState()
}

export const mutations = {
  SET_FIELDS(state, payload) {
    state.fields = payload
  }
}

export const actions = {
  setFields(context, payload) {
    context.commit('SET_FIELDS', payload)
  },
  clearFields(context) {
    context.commit('SET_FIELDS', {})
  }
}

export const getters = {
  fields: (state) => state.fields
}
