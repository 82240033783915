import Vue from 'vue'
import VModal from 'vue-js-modal'

import FloatingVue from 'floating-vue'
FloatingVue.options.container = false
FloatingVue.options.disposeTimeout = 50000000

Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: { clickToClose: false }
})

Vue.use(FloatingVue)
