
import { mapGetters } from 'vuex'
import EventCompact from '@/components/Event/Compact'
export default {
  components: {
    EventCompact
  },
  data() {
    return {
      errors: [],
      searchInProgress: false,
      q: '',
      searchResults: {}
    }
  },
  computed: {
    ...mapGetters({
      searchVisible: 'search/visible'
    }),
    resultCount() {
      return Object.keys(this.searchResults).length
    }
  },
  watch: {
    async q(newVal, oldVal) {
      if (newVal.length < 3) {
        this.searchResults = {}
        return
      }

      this.searchInProgress = true
      const result = await this.$axios.$get(`/api/event`, {
        params: {
          q: newVal
        }
      })

      this.searchResults = result.data
      this.searchInProgress = false
    }
  },
  methods: {
    search() {
      this.$router.push(
        this.localePath({
          name: 'search',
          query: {
            events: this.q
          }
        })
      )
      this.hideSearch()
    },
    showSearch() {
      this.$store.dispatch('search/show')
      this.$nextTick(() => {
        this.$refs.search.focus()
      })
    },
    hideSearch() {
      this.$refs.search.blur()
      this.searchResults = {}
      this.q = ''
      this.$store.dispatch('search/hide')
    }
  }
}
