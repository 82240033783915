
import Dropdown from '@/components/elements/Dropdown'
export default {
  components: {
    Dropdown
  },
  props: {
    languages: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    switchlang(val) {
      this.$router.push(this.switchLocalePath(val))
    }
  }
}
