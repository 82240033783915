
export default {
  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    return {
      ...i18nSeo,
      title: this.$t('meta.title')
    }
  }
}
