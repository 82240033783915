const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['landing'] = require('../middleware/landing.js')
middleware['landing'] = middleware['landing'].default || middleware['landing']

middleware['login'] = require('../middleware/login.js')
middleware['login'] = middleware['login'].default || middleware['login']

middleware['logout'] = require('../middleware/logout.js')
middleware['logout'] = middleware['logout'].default || middleware['logout']

export default middleware
