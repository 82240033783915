
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      promocode: null
    }
  },
  computed: {
    ...mapGetters({
      count: 'cart/count',
      cartOpen: 'cart/open',
      tickets: 'cart/content',
      currency: 'cart/currency',
      mobileLayout: 'mobileLayout'
    }),
    open() {
      return this.cartOpen
    },
    isMobile() {
      return this.mobileLayout.includes(this.$mq)
    }
  },
  watch: {
    promocode(newVal) {
      if (!newVal) {
        return
      }
      const re = /[^A-Z0-9]/gi
      this.$set(this, 'promocode', newVal.replace(re, '').toUpperCase())
    }
  }
}
