
import Dropdown from '@/components/elements/Dropdown'
import LangSwitch from '@/components/Header/MobLangSwitch'
export default {
  components: {
    Dropdown,
    LangSwitch
  },
  props: {
    languages: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    navigate(val) {
      this.$router.push(
        this.localePath({ name: 'slug', params: { slug: val } })
      )
    }
  }
}
