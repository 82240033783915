
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated'
    }),
    currentAuthComponent() {
      return this.isAuthenticated ? 'auth-profile' : 'auth-signin'
    }
  }
}
