
export default {
  name: 'Dropdown',
  props: {
    values: {
      type: null,
      default: () => {}
    },
    content: {
      type: null,
      default: null
    },
    chevron: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dropdownShow: false
    }
  },
  methods: {
    clickOutside() {
      if (this.dropdownShow) {
        this.hide()
      }
    },
    click(e) {
      this.$emit('link-pressed', e.target.getAttribute('data-value'))
      this.hide()
    },
    show() {
      this.$root.$emit('dropdown-show')
      this.dropdownShow = true
      const obj = this.$el
      const dropdown = obj.lastChild

      // set dropdown top position
      dropdown.setAttribute('style', 'top: ' + obj.clientHeight + 'px')

      // set dropdown side position
      if (
        parseInt(
          obj.getBoundingClientRect().left +
            dropdown.getBoundingClientRect().width
        ) > Number(window.innerWidth)
      ) {
        const res = obj.clientWidth - dropdown.getBoundingClientRect().width
        dropdown.setAttribute(
          'style',
          'left: ' + res + 'px; top: ' + obj.clientHeight + 'px'
        )
      }
    },
    hide() {
      this.dropdownShow = false
      this.$root.$emit('dropdown-hide')
    },
    toggle() {
      this.dropdownShow ? this.hide() : this.show()
    }
  }
}
