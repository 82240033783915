
export default {
  inheritAttrs: false,
  props: {
    img: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      required: true
    }
  }
}
