
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 404
      })
    }
  },
  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    return {
      ...i18nSeo,
      title: this.$t('meta.title')
    }
  }
}
