
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      total: 'cart/total',
      cartOpen: 'cart/open',
      count: 'cart/count',
      currency: 'cart/currency',
      paymentPage: 'router/paymentPage',
      descriptionPage: 'router/descriptionPage'
    }),
    isDisabled() {
      return !this.count
    },
    isOnPaymentPage() {
      return this.getRouteBaseName() === this.paymentPage
    },
    isOnDescriptionPage() {
      return (
        this.getRouteBaseName() === this.descriptionPage &&
        this.$store.getters['cookies/widget']
      )
    }
  },
  methods: {
    toggleCart() {
      this.$store.dispatch('cart/TOGGLE_CART', !this.cartOpen)
    },
    navigate() {
      this.$store.dispatch('cart/TOGGLE_CART', false)
      this.$router.push(this.localePath({ name: 'cart' }))
    },
    navigateToWidgetTickets() {
      this.$router.push(
        this.localePath({ name: 'tickets', query: this.$route.query })
      )
    },
    checkout() {
      this.$nuxt.$emit('cart:checkout')
    }
  }
}
