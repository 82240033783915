import Facebook from './facebook'
import Google from './google'
import Apple from './apple'
import GIS from './gis'

export default class Auth {
  constructor(Vue, options) {
    options = options || {}
  }

  facebook(options) {
    return new Facebook(options)
  }

  google(options) {
    return new Google(options)
  }

  gis(options) {
    return new GIS(options)
  }

  apple(options) {
    return new Apple(options)
  }
}
