export default class Apple {
  constructor(options) {
    this.options = options
    this.init()
  }

  init() {
    if (!window.AppleID || !window.AppleID.auth) {
      this.loadScript(document, 'script', 'apple-js')
    } else {
      this.login()
    }
  }

  async login() {
    try {
      const appleResponse = await window.AppleID.auth.signIn()

      const params = {
        ...appleResponse.authorization,
        redirect_uri: this.options.redirectURI
      }

      this.options.callback(params)
    } catch (error) {
      console.log(error)
    }
  }

  load() {
    window.AppleID.auth.init({
      clientId: this.options.clientId,
      state: 'origin:web',
      scope: 'name email',
      redirectURI: this.options.redirectURI,
      usePopup: true
    })

    this.login()
  }

  loadScript(d, s, id) {
    const fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    const js = d.createElement(s)
    js.id = id
    js.onload = () => {
      this.load()
    }
    js.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
    fjs.parentNode.insertBefore(js, fjs)
  }
}
