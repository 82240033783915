const initialState = () => {
  return {
    event: {},
    events: [],
    unlocked: []
  }
}

export const state = () => {
  return initialState()
}

export const mutations = {
  SET_EVENT(state, payload) {
    state.event = payload
  },
  SET_EVENTS(state, payload) {
    state.events = [...payload]
  },
  SET_UNLOCKED(state, payload) {
    state.unlocked = [...payload]
  }
}

export const actions = {
  setEvent(context, payload) {
    return context.commit('SET_EVENT', payload)
  },
  setEvents(context, payload) {
    return context.commit('SET_EVENTS', payload)
  },
  setUnlocked(context, payload) {
    return context.commit('SET_UNLOCKED', payload)
  },
  async fetchEvent(context, payload) {
    try {
      const event = await this.$axios.$get(`/api/event/${payload.id}`)

      context.commit('SET_EVENT', event)
    } catch (e) {
      console.log(e)
    }
  },
  async fetchCalendar(context, payload) {
    try {
      const events = await this.$axios.$get(`/api/org/${payload.id}/calendar`)

      context.commit('SET_EVENTS', events)
    } catch (e) {
      console.log(e)
    }
  },
  async checkPrivate(context, payload) {
    try {
      await this.$axios.post(`/api/event/${payload.event}/unlock`, payload)
      await this.$cookie.set(
        `ts__cookie__private__${payload.event}`,
        payload.promocode,
        { path: '/' }
      )
      context.commit('SET_UNLOCKED', [
        ...context.getters.unlocked,
        payload.event
      ])
    } catch (e) {
      await this.$cookiz.remove(`ts__cookie__private__${payload.event}`)
      context.commit('SET_UNLOCKED', [
        ...context.getters.unlocked.filter((item) => item !== payload.event)
      ])
    }
  }
}

export const getters = {
  event: (state) => state.event,
  events: (state) => state.events,
  unlocked: (state) => state.unlocked,
  isUnlocked: (state) => (id) => state.unlocked.includes(id),
  nearestEventDate: (state) => (needle) => {
    let date = new Date()

    const found = state.events.find((event) => event.event_date === needle)
    if (found) {
      date = new Date(needle)
    } else {
      const afterdates = state.events.filter((event) => {
        return new Date(event.event_date) - new Date(needle) > 0
      })

      if (afterdates.length) {
        date = new Date(afterdates[0].event_date)
      }
    }

    return date
  }
}
