export default function ({ isHMR, app, store, redirect, $config }) {
  if (isHMR) {
    return
  }

  if (
    store.getters['cookies/isWidget'] &&
    !store.getters['router/landingRoutes'].includes(app.getRouteBaseName())
  ) {
    return redirect(
      app.localePath({
        name: 'index'
      })
    )
  }
}
