export default function ({ $axios, app, store }) {
  $axios.defaults.validateStatus = (status) => {
    return (status >= 200 && status < 300) || [418].includes(status)
  }

  $axios.onRequest(async (config) => {
    config.data = config.data || {}
    if (process.env.TEST_ORDER) {
      config.data.test = 1
    }

    config.data.frontend_id = process.env.FRONTEND_ID

    // if (process.client) {
    //   if (['PUT', 'POST', 'DELETE'].includes(config.method.toUpperCase())) {
    //     try {
    //       config.data.recaptcha = await $recaptcha.execute('submit')
    //     } catch (e) {}
    //   }
    // }

    config.headers.frontendid = process.env.FRONTEND_ID
    config.headers.language = app.i18n.locale

    const session =
      app.$cookiz.get('ts__session') || store.getters['cookies/session']
    const _fbp = app.$cookiz.get('_fbp')
    const _fbc = app.$cookiz.get('_fbc')
    const _ttp = app.$cookiz.get('_ttp')
    const utm = await app.$cookiz.get('ts__cookie__query')
    if (session) {
      config.headers.fuelfid = session
    }

    if (_fbp) {
      config.headers['X-Fbp'] = _fbp
    }

    if (_fbc) {
      config.headers['X-Fbc'] = _fbc
    } else if (utm) {
      const { fbclid } = utm
      if (fbclid) {
        config.headers['X-Fbc'] = `fb.1.${Date.now()}.${fbclid}`
      }
    }

    if (_ttp) {
      config.headers['X-Ttp'] = _ttp
    }

    if (utm) {
      const { ttclid } = utm
      if (ttclid) {
        config.headers['X-Ttc'] = ttclid
      }
    }

    return config
  })

  $axios.onResponse(async (response) => {
    if (response.status === 418) {
      response.data.waiting = true
    }

    try {
      const session =
        app.$cookiz.get('ts__session') || store.getters['cookies/session']
      const { fuelfid } = response.headers
      if (fuelfid && session && session !== fuelfid && process.client) {
        await app.$cookiz.set('ts__session', fuelfid, {
          sameSite: 'none',
          secure: true,
          path: '/'
        })
        await store.dispatch('cookies/setSession', fuelfid)
      }
    } catch (e) {
      console.log(e)
    }

    return response
  })

  // $axios.onError((err) => {})

  // $axios.onRequestError((err) => {})

  $axios.onResponseError((err) => {
    let message = err?.message
    const response = err?.response
    const config = err?.config
    const showNotification = !('notification' in config && !config.notification)

    if (response) {
      const { status, data } = err.response
      const { errors } = data
      if (!!data && data?.message) {
        message = data.message
      }

      if (process.client && status !== 425) {
        const { i18n } = data
        if (i18n && app.i18n.te(i18n)) {
          message = app.i18n.t(i18n)
        }
      }

      if (
        status === 400 &&
        ((errors && Array.isArray(errors)) || Array.isArray(data))
      ) {
        const _errors = errors || data
        const fields = {}
        _errors.map((error) => {
          fields[error.field] = [
            app.i18n.te(error.i18n) ? app.i18n.t(error.i18n) : error.message
          ]

          return error
        })

        if (_errors.length) {
          message = false
        }

        store.dispatch('errors/setFields', fields)
      }

      if (message && showNotification) {
        app.$notify({
          type: 'error',
          group: 'ts',
          text: message
        })
      }
    }

    return Promise.reject(err)
  })
}
