
import { mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading
  },
  props: {
    fullPage: {
      type: Boolean,
      default: false
    },
    opacity: {
      type: Number,
      default: 0.75
    }
  },
  computed: {
    ...mapGetters({
      loadingText: 'loadingText',
      loading: 'loading'
    })
  }
}
