
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      categories: []
    }
  },
  async fetch() {
    this.categories = await this.$axios.$get(`api/categories`)
  },
  computed: {
    ...mapGetters({
      event: 'event/event'
    }),
    topCategories() {
      const categories = [...this.categories]
        .sort((a, b) => a.id - b.id)
        .slice(0, 5)
      return categories
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.$fetch()
      }
    }
  }
}
