export default class Google {
  constructor(options) {
    this.options = options
    this.init()
  }

  init() {
    if (!window.gapi || !window.gapi.auth2) {
      this.loadScript(document, 'script', 'google-js')
    } else {
      this.login()
    }
  }

  login() {
    const authInstance = window.gapi.auth2.getAuthInstance()
    const authData = authInstance.currentUser.get().getAuthResponse()

    if (!authData.access_token) {
      authInstance
        .signIn()
        .then((googleUser) => {
          const authResponse = googleUser.getAuthResponse()
          this.options.callback(authResponse.access_token)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      this.options.callback(authData.access_token)
    }

    return false
  }

  load() {
    const that = this
    window.gapi.load('auth2', () => {
      window.auth2 = window.gapi.auth2
        .init({
          client_id: that.options.client_id,
          cookiepolicy: 'single_host_origin',
          scope: 'profile'
        })
        .then(() => {
          that.login()
        })
    })
  }

  loadScript(d, s, id) {
    const fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    const js = d.createElement(s)
    js.id = id
    js.onload = () => {
      this.load()
    }
    js.src = '////apis.google.com/js/platform.js'
    js.setAttribute('async', '')
    js.setAttribute('defer', '')
    fjs.parentNode.insertBefore(js, fjs)
  }
}
