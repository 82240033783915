
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      promoChecked: false
    }
  },
  computed: {
    ...mapGetters({
      total: 'cart/total',
      count: 'cart/count',
      fees: 'cart/fees',
      currency: 'cart/currency',
      paymentPage: 'router/paymentPage',
      cartPages: 'router/cartPages'
    }),
    isDisabled() {
      return !this.count
    },
    isOnPaymentPage() {
      return this.getRouteBaseName() === this.paymentPage
    },
    cartRoute() {
      return this.cartPages.includes(this.getRouteBaseName())
    }
  },
  methods: {
    navigate() {
      this.$router.push(
        this.localePath({
          name: this.paymentPage
        })
      )
    },
    togglePromo() {
      this.promoChecked = !this.promoChecked
    },
    checkout() {
      this.$nuxt.$emit('cart:checkout')
    }
  }
}
