export default async function ({ isHMR, store, redirect, app }) {
  if (isHMR) {
    return
  }

  const check = await app.$api.auth.use('data').check()
  if (!check.uid) {
    return redirect(app.localePath({ name: 'login' }))
  }

  store.dispatch('SET_CLIENT_ID', check.uid)
}
