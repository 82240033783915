import api from '@/api'

export default function ({ $axios, app }, inject) {
  const initializeApiGroups = [
    ...Object.entries(api).map(([key, value]) => [key, value($axios)])
  ].reduce((obj, [key, val]) => {
    obj[key] = val
    return obj
  }, {})

  app.$api = initializeApiGroups
  inject('api', initializeApiGroups)
}
