export default class Facebook {
  constructor(options) {
    this.options = options
    this.init()
  }

  init() {
    if (!window.FB) {
      this.loadScript(document, 'script', 'facebook-jssdk')
    } else {
      this.login()
    }
  }

  login() {
    this.check((response) => {
      if (response.status === 'connected') {
        this.options.callback(response.authResponse.accessToken)
      } else {
        window.FB.login(
          (response) => {
            if (response.authResponse) {
              this.options.callback(response.authResponse.accessToken)
            }
          },
          { scope: 'user_gender,email,user_birthday', return_scopes: 'true' }
        )
      }
    })

    return false
  }

  check(callback = null) {
    window.FB.getLoginStatus((response) => {
      if (callback && {}.toString.call(callback) === '[object Function]') {
        callback(response)
      }
    })
  }

  load() {
    const that = this
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: that.options.appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v7.0'
      })
      window.FB.AppEvents.logPageView()
      this.login()
    }
  }

  loadScript(d, s, id) {
    const fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    const js = d.createElement(s)
    js.id = id
    js.onload = () => {
      this.load()
    }
    js.src = 'https://connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  }
}
