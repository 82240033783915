export default function ({ app, isDev }, inject) {
  inject('cookie', {
    set(name, value, options) {
      const defaultOptions = isDev
        ? {}
        : {
            sameSite: 'none',
            secure: true
          }

      app.$cookiz.set(name, value, {
        ...defaultOptions,
        ...options
      })
    }
  })
}
