
export default {
  data() {
    return {
      promocode: null
    }
  },
  methods: {
    applyPromocode() {
      this.$store
        .dispatch('cart/SET_PROMOCODE', this.promocode)
        .then((response) => {
          this.promocode = null
          this.$emit('promo-checked')

          this.$notify({
            type: 'success',
            group: 'ts',
            title: this.$t('headings.success'),
            text: this.$t('text.promocode_activated')
          })
        })
    }
  }
}
